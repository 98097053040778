import React from "react";
import styled from "styled-components";
import Slider from "infinite-react-carousel";

import FirstImageUrl from "../images/carousel/Zug.jpg";
import SecondImageUrl from "../images/carousel/Zytturm.jpg";
import ThirdImageUrl from "../images/carousel/Luzern2.jpg";
import FourthImageUrl from "../images/carousel/SeeCh.jpg";

const Image = styled.img`
  height: 500px;
  width: 100%;
  object-fit: cover;
`;

export default () => (
  <Slider
    autoplaySpeed={8000}
    autoplay
    dots
  >
    <div>
      <Image src={FirstImageUrl} alt="Zug" />
    </div>
    <div>
      <Image src={SecondImageUrl} alt="Zytturm" />
    </div>
    <div>
      <Image src={ThirdImageUrl} alt="Luzern 2" />
    </div>
    <div>
      <Image src={FourthImageUrl} alt="See" />
    </div>
  </Slider>
);