import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Carousel from "../components/Carousel";

import VorsorgeVersicherungImage from "../images/VorsorgeVersicherung.jpg";
import LuzernImage from "../images/Luzern.jpg";
import VirtualOfficeImage from "../images/VirtualOffice.jpg";
import SempacherseeImage from "../images/Sempachersee.jpg";

const Box = styled.div`
  padding: 1.45rem .3rem 1.45rem .3rem;
  border: 1px solid;
  border-color: var(--color-primary-border);
  width: 25%;
  text-align: center;
  margin: 2.5vh .5vh;

  @media(max-width: 1200px) {
    width: 100%;
  }
`;
const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  @media(max-width: 770px) {
    flex-direction: column !important;
  }
`;
const Text = styled.p`
  padding: 10px 10px 10px 10px;
  line-height: 150%;
  @media(max-width: 1100px) {
    font-size: 14px !important;
  }
  @media(max-width: 950px) {
    font-size: 12px !important;
  }
  @media(max-width: 770px) {
    font-size: 15px !important;
  }
`;
const Image = styled.img`
  width: 90%;
  height: 45%;
  object-fit: cover;
  border:  1px solid var(--color-primary-border);
`;
const TextTitle = styled.h3`
  @media(max-width: 950px) {
    font-size: 14px !important;
  }
  @media(max-width: 770px) {
    font-size: 1.17em !important;
  }
`;

export default () => (
  <Layout>
    <Carousel />
    <Toolbar style={{ marginTop: "5vh" }}>
      <Box>
        <Image src={VorsorgeVersicherungImage} alt="Vorsorge und Versicherung" />
        <TextTitle>Vorsorge und Versicherung</TextTitle>
        <Text>Gerne beraten wir Sie in den Themen Vorsorge und Versicherung. Zudem können wir Ihnen auf Sie abgestimmte Versicherungen vermitteln.</Text>
      </Box>
      <Box>
        <Image src={LuzernImage} alt="Kredite" />
        <TextTitle>Kredite und Beteiligungen</TextTitle>
        <Text>Haben Sie Fragen zu Krediten oder Beteiligungen? Wir beraten Sie gerne in allen Bereichen.</Text>
      </Box>
      <Box>
        <Image src={VirtualOfficeImage} alt="Virtual Office" />
        <TextTitle>Virtual Office</TextTitle>
        <Text>Wir bieten Ihnen ein virtuelles Büro oder auch eine sogenannte Briefkastenadresse für ihr Unternehmen an.</Text>
      </Box>
      <Box>
        <Image src={SempacherseeImage} alt="Buchhaltung" />
        <TextTitle> Buchhaltung</TextTitle>
        <Text>Brauchen Sie Hilfe bei der Verwaltung Ihrer Buchhaltung? Gerne helfen wir Ihnen dort weiter. Melden Sie sich bei uns! </Text>
      </Box>
    </Toolbar>
  </Layout>
);